import { React, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useParams } from "react-router-dom";
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import './index.css';


export default function Item() {
    let { item, item_name } = useParams();
    const [itemContent, setItemContent] = useState("");
    import(`./${item}s/${item_name}`).then(x => fetch(x.default)).then(x => x.text()).then(x => x.split("\n").slice(1).join("\n")).then(x => setItemContent(x));

    return (
        <section>
            <Markdown className="prose prose-siteScheme dark:prose-invert" rehypePlugins={[rehypeRaw]}>{itemContent}</Markdown>
        </section>
    );
}