import React from 'react';
import ItemList from './itemList';
import './index.css';


export default function Home() {
  return (
    <section>
      <section className="prose prose-siteScheme dark:prose-invert">
        <h1>Hi, I'm Douglas!</h1>
        <h2>About me</h2>
        <p>I'm a 20 year old Computer Science student. I'm interested in computer programming, networks, video games and music.</p>
      </section>
      <ItemList key="articles" title="Articles" items_list={require.context("./articles", false, /\.MD$/)} url_prefix="article" view_all={true} />
      <ItemList key="projects" title="Projects" items_list={require.context("./projects", false, /\.MD$/)} url_prefix="project" view_all={true} />

      <section className="prose prose-siteScheme dark:prose-invert mt-9">
        <h2>Links</h2>
        <ul>
          <li><a href="https://github.com/synackuk">Github</a></li>
          <li><a href="https://www.youtube.com/@foggymaths">YouTube</a></li>
          <li><a href="https://www.x.com/synackuk">Twitter</a></li>
        </ul>
        <h2>Tools</h2>
        <h3>Software</h3>
        <ul>
          <li><a href="https://vscodium.com">VSCodium</a></li>
          <li><a href="https://www.sublimetext.com">Sublime Text</a></li>
          <li><a href="https://iterm2.com/index.html">iTerm 2</a></li>
        </ul>
        <h3>Hardware</h3>
        <ul>
          <li><a href="https://support.apple.com/en-gb/111901">Laptop: 16" MacBook Pro 2021</a></li>
          <li>Gaming PC:</li>
          <ul>
            <li><a href="https://www.amd.com/en/product/11826">CPU: AMD Ryzen 5700x</a></li>
            <li><a href="https://www.amd.com/en/products/graphics/desktops/radeon/6000-series/amd-radeon-rx-6600-xt.html">GPU: AMD Radeon RX 6600 XT</a></li>
            <li>RAM: 32GB DDR4</li>
          </ul>
        </ul>
      </section>
    </section>
  );
}
