import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './home';
import ItemList from './itemList';
import Item from './item';
import { BrowserRouter as Router, NavLink, Routes, Route } from 'react-router-dom';


function NavButton({ title, href, border_colour }) {
  const baseStyles = "text-xl border-b-2";
  return (
    <NavLink to={href} className={({ isActive }) => isActive ? `${baseStyles} border-${border_colour}` : `${baseStyles} border-transparent hover:border-${border_colour}`}>{title}</NavLink>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <header className={"mb-24"}>
      <nav className="flex flex-row justify-between max-w-md">
        <NavButton title="Home" href="/" border_colour="red-600" />
        <NavButton title="Projects" href="/projects" border_colour="green-500" />
        <NavButton title="Articles" href="/articles" border_colour="blue-400" />
      </nav>
    </header>
    <main className={"grow mb-12"}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<ItemList key="projects" title="Projects" items_list={require.context("./projects", false, /\.MD$/)} url_prefix="project" max_listings={-1}/>} />
        <Route path="/articles" element={<ItemList key="articles" title="Articles" items_list={require.context("./articles", false, /\.MD$/)} url_prefix="article" max_listings={-1}/>} />
        <Route path="/:item/:item_name" element={<Item />} />
      </Routes>
    </main>
    <footer className={"py-6"}>Copyright &copy; Douglas Inglis 2024</footer>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
